import React from "react";
import Menu from '../menu/Menu.js'
import salsa from '../../images/salsa.png';
import share from '../../images/share.png';
import photo from '../../images/photo1.jpeg';
import video from '../../images/vid1.mp4';
import gif from '../../images/gif1.mp4';
import boomerang from '../../images/boomerang1.mp4';
import './about.css';

function About(){
    return(
        <div className="about-wrapper">
            <Menu />
            <div className="about-content-wrapper">
                <div className="about-header">
                    <div className="about-text">
                        <div className="about-h2">
                            <p>Your Event.</p>
                            <p>Your Photobooth.</p>
                        </div>
                        <div className="about-para">
                            Customize your event's photobooth the way you want it.  With Utah Photobooth Company's robust photobooth platform, make your event your own.
                        </div>
                    </div>
                    <div className="about-salsa">
                        <img src ={salsa} className="salsa-image" alt=""/>
                    </div>
                </div>
                <div className="about-body">
                    <div className="about-body-h2">
                        Select your capture preferences
                    </div>
                    <div className="about-body-images">
                        <div><img src={photo} className="about-photo"/><div>photo</div></div>
                        <div><video src={video} className="about-photo" autoPlay loop muted></video><div>video</div></div>
                        <div><video src={gif} className="about-photo" autoPlay loop muted></video><div>gif</div></div>
                        <div><video src={boomerang} className="about-photo" autoPlay loop muted></video><div>boomerang</div></div>
                    </div>
                </div>
                <div className="about-footer">
                    <div>
                        <div className="about-share">Share your event</div>
                        <div className="about-share-para">Each event will be provided with a microsite allowing attendees to view all of the photobooth's activity as well as share it across social media.</div>
                    </div>
                    <div>
                        <img src ={share} className='about-share-image' alt=''/>
                    </div>
                </div>

            </div>
               
        </div>
    )
}

export default About