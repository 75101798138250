import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Home from './components/home/Home';
import About from './components/about/About';
import Packages from './components/packages/Packages';
import Contact from './components/contact/Contact';
import Thanks from './components/thanks/Thanks';
import Snapcamper from './components/snapcamper/SnapCamper';

const router = createBrowserRouter([
  {path: '/', element: <Home />},
  {path: '/about', element: <About /> },
  {path: '/packages', element: <Packages />},
  {path: '/contact', element: <Contact />},
  {path: '/thanks', element: <Thanks />},
  {path: '/snapcamper', element: <Snapcamper />}

])


function App() {
  return <RouterProvider router={router} />;
}

export default App;
