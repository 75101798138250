import Menu from '../menu/Menu.js';
import './packages.css';



function Packages() {
    return (
        <div className='packages-wrapper'>
            <Menu />
            <div className='pricing-guide-wrapper'>
                <div className='pricing-guide-head'>
                    Pricing Guide
                </div>
                <div className='pricing-guide-items'>
                    <div className='pricing-guide-1'>
                        <div className="guide-head-1">Timp</div>
                        <div className='guide-items-1'>
                            <div className='guide-items'>

                                <div>- 3 hour rental ($100/additional hour)</div>
                                <div>- Receive photos by email and text</div>
                                <div>- 10 props</div>
                                <div>- Physical Background</div>
                                <div>- Capture Options:
                                    <p>- Photo</p>
                                    <p>- Generic live gallery</p>
                                </div>
                            </div>
                            <div className='guide-price-1'>$650</div>
                        </div>
                    </div>


                    <div className='pricing-guide-2'>
                        <div className='guide-head-2'>Arches</div>
                        <div className='guide-items-2'>
                            <div className='guide-items'>
                                <div>- 3 hour rental($100/additional hour)</div>
                                <div>- Receive photos by email and text</div>
                                <div>- 20 props</div>
                                <div>- Custom overlay</div>
                                <div>- Custom live gallery</div>
                                <div>- Physical background</div>
                                <div>- Capture options:
                                    <p>- Photo </p>
                                        <p>- Boomerang</p>
                                        <p>- GIF</p>
                                </div>

                            </div>
                            <div className='guide-price-2'>$850</div>
                        </div>
                    </div>


                    <div className='pricing-guide-3'>
                        <div className='guide-head-3'>Zion's</div>
                        <div className='guide-items-3'>
                            <div className='guide-items'>
                                <div>- 3 hour rental ($100/additional hour)</div>
                                <div>- Receive photos by email and text</div>
                                <div>- 30 props</div>
                                <div>- Custom overlay</div>
                                <div>- Custom live gallery</div>
                                <div>- Physical background</div>
                                <div>- Custom screens</div>
                                <div>- Analytics access</div>
                                <div>- Survey access</div>
                                <div>- Capture options:
                                    <p>- Photo</p>
                                    <p>- Boomerang</p>
                                    <p>- Video</p>
                                    <p>- GIF</p>
                                </div>

                            </div>
                            <div className='guide-price-3'>$1050</div>
                        </div>
                    </div>
                    <div className='pricing-add-ons'>
                        <div className='add-ons-h2'>Add Ons</div>
                        <div className='add-ons-items'>
                            <div className="add-ons-individual">
                                <div className='add-ons-bold'>Props</div>
                                <div>- $50</div>
                            </div>
                            <div className="add-ons-individual">
                                <div className='add-ons-bold'> Survey Features</div>
                                <div>- $150</div>
                            </div>
                            <div className="add-ons-individual">
                                <div className='add-ons-bold'>Data Analytics</div>
                                <div>- $150</div>
                            </div>
                            <div className="add-ons-individual">
                                <div className='add-ons-bold'>Customized Screens</div>
                                <div>- $100</div>
                            </div>
                            <div className="add-ons-individual">
                                <div className='add-ons-bold'>Customized Overlays</div>
                                <div>- $100</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Packages


